import {
  FormControl,
  InputBase,
  MenuItem,
  Paper,
  Select,
  styled,
} from '@mui/material';
import { Link } from 'gatsby';
import { LocalizedLink, useLocalization } from 'gatsby-theme-i18n';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { isBrowser } from '../../helpers';
import { useArticles } from '../../helpers/use-articles';
import logo from '../../images/logo.svg';
import * as css from './header.module.css';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    'border': '1px solid #ced4da',
    'fontSize': 16,
    'padding': '10px 26px 10px 12px',
    'transition': theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

export const Header = () => {
  const { t } = useTranslation();

  const { locale } = useLocalization();
  const { sortedGeneral } = useArticles(locale);

  const firstArticleSlug = sortedGeneral[0].childMdx.frontmatter.slug;

  const [headerKey, setHeaderKey] = React.useState(false);

  React.useEffect(() => {
    setHeaderKey(true);
  }, [setHeaderKey]);

  const middleLinks = (
    <div className={css.linksContainer}>
      <LocalizedLink className={css.link} to="/#app">
        {t('app')}
      </LocalizedLink>
      <LocalizedLink className={css.link} to="/#therapists">
        {t('therapists')}
      </LocalizedLink>
      <LocalizedLink className={css.link} to={firstArticleSlug}>
        {t('articles')}
      </LocalizedLink>
      <LocalizedLink className={css.link} to="/#form">
        {t('contact')}
      </LocalizedLink>
      <Link className={css.linkNoWrap} to="https://portal.copingcard.com">
        {t('portal')}
      </Link>
    </div>
  );

  return (
    <Paper
      key={`key-${headerKey}`}
      className={css.containerHeader}
      elevation={5}
    >
      <div className={css.rowOne}>
        <LocalizedLink to="/">
          <div className={css.logo}>
            <img src={logo} alt="CopingCard Logo" width="200px" />
          </div>
        </LocalizedLink>

        <div className={css.oneRow}>{middleLinks}</div>
        <div className={css.languageSelector}>
          <h3 className={css.language}>{t('language')}: </h3>
          <FormControl fullWidth className={css.languageDropdown}>
            <Select
              classes={{
                icon: css.selectIcon,
              }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={locale}
              label="Language"
              sx={{
                color: 'white',
                fontWeight: 500,
                borderColor: 'white',
              }}
              onChange={(event) => {
                const lang = event.target.value?.startsWith('ru') ? '/ru' : '';
                const oldPathName = window?.location.pathname;
                const newPathName = oldPathName.replace('/ru', '');

                const route = isBrowser
                  ? window?.location.protocol +
                    '//' +
                    window?.location.host +
                    lang +
                    newPathName +
                    window?.location.search
                  : '';

                isBrowser && window.location.replace(route);
              }}
              input={<BootstrapInput />}
            >
              <MenuItem
                value={'en'}
                classes={{
                  selected: css.selectMenuItem,
                }}
              >
                En
              </MenuItem>
              <MenuItem
                value={'ru'}
                classes={{
                  selected: css.selectMenuItem,
                }}
              >
                Ru
              </MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      <div className={css.twoRows}>{middleLinks}</div>
    </Paper>
  );
};
