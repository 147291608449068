import { graphql, useStaticQuery } from 'gatsby';

export const useArticles = (locale: string) => {
  const data = useStaticQuery(graphql`
    query ArticlesQuery {
      articles: allFile(
        filter: {
          sourceInstanceName: { eq: "articles" }
          extension: { ne: "png" }
        }
      ) {
        nodes {
          childMdx {
            excerpt
            fields {
              locale
            }
            frontmatter {
              title
              slug
              category
              sortOrder
            }
          }
        }
      }
    }
  `);

  const currentLanguageArticles =
    data.articles.nodes.filter((a) => a.childMdx.fields.locale === locale) ||
    [];

  const sortedGeneral = currentLanguageArticles
    .filter((a) => a.childMdx.frontmatter.category === 'general')
    .sort(
      (a, b) =>
        a.childMdx.frontmatter.sortOrder - b.childMdx.frontmatter.sortOrder,
    );

  const sortedPractceManagement = currentLanguageArticles
    .filter((a) => a.childMdx.frontmatter.category === 'practiceManagement')
    .sort(
      (a, b) =>
        a.childMdx.frontmatter.sortOrder - b.childMdx.frontmatter.sortOrder,
    );

  const sortedResearch = currentLanguageArticles
    .filter((a) => a.childMdx.frontmatter.category === 'research')
    .sort(
      (a, b) =>
        a.childMdx.frontmatter.sortOrder - b.childMdx.frontmatter.sortOrder,
    );

  return { sortedGeneral, sortedPractceManagement, sortedResearch };
};
