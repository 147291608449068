import { LocalizedLink } from 'gatsby-theme-i18n';
import * as React from 'react';
import CookieConsent, {
  Cookies,
  getCookieConsentValue,
} from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
import * as css from './footer.module.css';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TelegramIcon from '@mui/icons-material/Telegram';

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className={css.container}>
      <div className={css.columnOne}>
        <LocalizedLink className={css.link} to="/privacy">
          {t('privacy')}
        </LocalizedLink>
        <LocalizedLink className={css.link} to="/terms">
          {t('terms')}
        </LocalizedLink>
      </div>
      <div className={css.columnTwo}>
        <div className={css.address}>
          17657 Candlewood Ter, Boca Raton, FL 33487, USA
        </div>
        <div className={css.copyright}>
          © 2023 CopingCard, Inc. All Rights Reserverd.
        </div>
      </div>
      <div className={css.columnTwo}>
        <div>{t('followUs')}:</div>
        <div>
          <InstagramIcon
            className={css.socialIcon}
            onClick={() => {
              window.open('https://www.instagram.com/copingcard/', '_blank');
            }}
          />
          <FacebookIcon
            className={css.socialIcon}
            onClick={() => {
              window.open('https://www.facebook.com/CopingCard', '_blank');
            }}
          />

          <TelegramIcon
            className={css.socialIcon}
            onClick={() => {
              window.open('https://t.me/+neuy3wt9e305YmMy', '_blank');
            }}
          />
        </div>
      </div>
      <CookieConsent
        location="bottom"
        buttonText={t('accept')}
        enableDeclineButton
        declineButtonText={t('decline')}
        cookieName="cc-gdpr-google-analytics"
        style={{ background: '#2b7895' }}
        buttonStyle={{
          borderRadius: '5px',
          background: '#012e3f',
          color: 'white',
          fontWeight: 600,
        }}
        declineButtonStyle={{
          borderRadius: '5px',
          background: '#b21f00',
          fontWeight: 600,
        }}
      >
        {t('cookies')}
        <LocalizedLink className={css.cookiesLink} to="/privacy">
          {t('cookiesPrivacy')}
        </LocalizedLink>
      </CookieConsent>
    </div>
  );
};
