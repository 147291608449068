// extracted by mini-css-extract-plugin
export var containerHeader = "header-module--containerHeader--HyjND";
export var language = "header-module--language--WYUb8";
export var languageDropdown = "header-module--languageDropdown--KQBKw";
export var languageSelector = "header-module--languageSelector--lkjru";
export var link = "header-module--link--7Ets4";
export var linkNoWrap = "header-module--linkNoWrap--71nmE";
export var linksContainer = "header-module--linksContainer--f5A-v";
export var logo = "header-module--logo--bLO3n";
export var oneRow = "header-module--oneRow--Wt211";
export var rowOne = "header-module--rowOne--77ZnH";
export var selectIcon = "header-module--selectIcon--a3R0F";
export var selectMenuItem = "header-module--selectMenuItem--jFI2F";
export var twoRows = "header-module--twoRows--xHsqO";